<script>
import DatePicker from "@/components/Calendars.vue";
import axios from "axios";
export default {
  components: {
    DatePicker,
  },
  data: () => ({
    search: "",
    items: [],
    filter: {
      dateStart: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    headers: [
      { text: "", value: "cod_barras", sortable: false },
      { text: "Ag.Origem", value: "origem", sortable: false },
      { text: "Ag.Destino", value: "destino", sortable: false },
      { text: "Remetente", value: "remetente", sortable: false },
      { text: "Destinatario", value: "destinatario", sortable: false },
      { text: "Valor", value: "valor_total", sortable: false },
      { text: "Cobrança", value: "cobranca", sortable: false },
      { text: "Cancelado", value: "cancelada", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
  }),
  created() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      const response = await axios.get("excess-baggage");
      this.items = response.data;
    },
  },
};
</script>
